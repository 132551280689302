import { Controller } from "stimulus";
import { Popover } from "bootstrap"; // Ensure bootstrap is properly imported

export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Stimulus controller connected");
    this.initializePopover();
  }

  initializePopover() {
    const formContent = `
      <form id="upload_form" action="/custom_training_data" method="post" enctype="multipart/form-data">
        <div class="col-auto d-flex">
          <input class="form-control" type="file" id="file_input" name="custom_training_data[csv_file]" required>
          <button type="submit" class="btn btn-sm btn-primary m-2 ml-auto">Upload</button>
        </div>
      </form>
    `;

    const popover = new Popover(this.buttonTarget, {
      html: true,
      content: formContent,
      sanitize: false,
      template: '<div class="popover" role="tooltip" style="max-width: 400px; width: 100%;"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
  }
}
import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["keepFormId", "keepFormIdError", "cancelSubscriptionForm", "downgradeButton"];

  connect() {
    console.log("subscription controller connected...");
  }

  _createSession(planId) {
    const stripe = Stripe(this.data.get("stripeKey"));

    Rails.ajax({
      url: `/subscriptions?plan_id=${planId}`,
      type: 'POST',
      dataType: 'json',
      success: (data) => {
        const checkoutSessionId = data.checkout_session_id;
        stripe.redirectToCheckout({
          sessionId: checkoutSessionId
        }).then(function (result) {
          console.error(result.error.message)
        });
      },
      error: (_data) => {
        console.error('Something went wrong generating a stripe checkout session id');
      }
    })
  }

  changePlan(event) {
    event.stopImmediatePropagation(); // prevent multiple clicks!
    event.preventDefault();
    console.log(event.target.dataset);
    this._createSession(event.target.dataset.planId);
  }

  cancel(event) {
    event.stopImmediatePropagation(); // prevent multiple clicks!
    event.preventDefault();
    if(!this.keepFormIdTarget.value) {
      this.keepFormIdTarget.classList.add('is-invalid');
      console.log('no form selected!');
    } else {
      console.log('downgrading to free plan!');
      this.keepFormIdTarget.classList.remove('is-invalid');
      this.downgradeButtonTarget.setAttribute("disabled", "disabled");
      this.cancelSubscriptionFormTarget.submit();
    }
  }
}

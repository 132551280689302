import { Controller } from "stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["jsonData", "response", "submitButton", "apikey"]

  static values = { apiUrl: String }

  connect() {
  }

  toggleVisibility(event) {
    event.preventDefault();
    console.log("Toggling visibility");
    this.apikeyTarget.classList.toggle('blurred');
    this.apikeyTarget.classList.toggle('revealed');

    // Change button text
    event.currentTarget.textContent = this.apikeyTarget.classList.contains('blurred') ? 'Click to reveal' : 'Hide API key';
  }

  regenerateKey(event) {
    event.preventDefault();
    
    if (!confirm('Are you sure you want to regenerate the API key?')) {
      return;
    }

    fetch('/regenerate_api_key', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('API key regenerated:', data);
      this.apikeyTarget.textContent = data.api_key; // Assuming the new API key is in data.new_api_key
    })
    .catch(error => {
      // Handle errors
      console.error('There was a problem with the fetch operation:', error);
    });
  }

  createSpinner() {
    var spinnerDiv = document.createElement('div');
    spinnerDiv.className = 'spinner-grow';
    spinnerDiv.role = 'status';

    var span = document.createElement('span');
    span.className = 'visually-hidden';
    span.textContent = 'Loading...';

    spinnerDiv.appendChild(span);
    return spinnerDiv;
  }

  submitForm(event) {
    event.preventDefault();

    var jsonData = this.jsonDataTarget.value;
    var responseDiv = this.responseTarget;
    var spinner = this.createSpinner();

    var submitButton = this.submitButtonTarget;
    submitButton.disabled = true; // disable submit button when request starts

    var xhr = new XMLHttpRequest();
    xhr.open("POST", this.apiUrlValue, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("Api-Key", this.apikeyTarget.textContent);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) { // if complete
        responseDiv.removeChild(spinner); // remove spinner when request is complete
        submitButton.disabled = false; // enable submit button when request is complete

        if (xhr.status === 200) { // check if "OK" (200)
          var responseJson = JSON.parse(xhr.responseText);
          var isSpam = responseJson.content.spam.result;

          var spamComment = isSpam ? "The content is classified as spam." : "The content is not classified as spam.";
          var summarySentence = this.generateSummarySentence(responseJson);
          var riskyUrlsComment = this.generateRiskyUrlsComment(responseJson);

          responseDiv.textContent = 'Summary:\n' + spamComment + '\n\n' + summarySentence + '\n\n' + riskyUrlsComment + '\n\nJSON Response:\n' + JSON.stringify(responseJson, null, 4);
        } else {
          responseDiv.textContent = "Error: " + xhr.status;
        }
      }
    }

    responseDiv.textContent = ''; // clear response div when request starts
    responseDiv.appendChild(spinner); // append spinner to the response div when request starts

    xhr.send(jsonData);
  }

  generateSummarySentence(jsonData) {
    var city = jsonData.ip.geo.data.city.names.en;
    var country = jsonData.ip.geo.data.country.names.en;
    var ipBlocked = jsonData.ip.blocked.result;
    var emailBlocked = jsonData.email.blocked.result;
    var languageData = jsonData.content.language.data;
    var mostProbableLanguage = languageData.reduce((prev, current) => (prev[1] > current[1]) ? prev : current)[0];

    return `This content originates from ${city}, ${country} from an IP address that is ${ipBlocked ? 'blocked' : 'not blocked'}, with an email that is ${emailBlocked ? 'blocked' : 'not blocked'}. The language of the content is most likely ${mostProbableLanguage}.`;
  }

  generateRiskyUrlsComment(jsonData) {
    var riskyUrls = jsonData.content.risky_urls.data;
    var comment = "Risky URLs: ";

    if (!Array.isArray(riskyUrls)) {
      return comment + "None";
    }

    // filter out urls that don't have keyword counts
    var riskyUrlsWithKeywords = riskyUrls.filter(function (urlObject) {
      return Object.keys(urlObject.keyword_counts).length > 0;
    });

    if(riskyUrlsWithKeywords.length === 0) {
      comment += "None";
    } else {
      riskyUrlsWithKeywords.forEach(function (urlObject, index) {
        comment += "\n" + (index+1) + ". " + urlObject.url;
      });
    }

    return comment;
  }
}